import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {
  Alert,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTruckPickup,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

import { StateTax, statemap, stateabbs } from './StateTaxDropDown';

//images
import logo from '../images/fixnetics-logo.png';
import loginIcon from '../images/login-icon.png';
import videoBackground from '../images/background.mp4';

// eslint-disable-next-line
const emailtester = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Main extends React.Component {

  initState = {
    message: '',
    errors: {},
    companyname: '',
    email: '',
    password: '',
    password2: '',
    firstname: '',
    lastname: '',
    statetax: '8.00',
    pdpa : false,
    modal_terms : false,
    modal_privacy : false,
    registrationNo: '',
  }

  constructor(props) {
    super(props);
    this.state = this.initState;
    this.toggle_terms = this.toggle_terms.bind(this);
    this.toggle_privacy = this.toggle_privacy.bind(this);
  }

  onChange = (e) => {
    if(e.target.name === 'pdpa'){
      this.setState({
        [e.target.name]: e.target.checked,
      });
    }else{
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  validateForm = () => {
    const errors = { ...this.state.errors };

    Object.keys(this.state).forEach((fieldname) => {

      if (fieldname === 'errors' || fieldname === 'message') {
        return true;
      }

      const value = this.state[fieldname];

      if (fieldname === 'email') {
        if (!emailtester.test(value) || !value) {
          errors[fieldname] = 'Invalid email';
        } else {
          errors[fieldname] = null;
        }
        return true;
      }

      if (
        fieldname === 'password' ||
        fieldname === 'password2'
      ) {
        if (this.state.password.length >= 7
            && this.state.password2.length >= 7
            && (this.state.password !== this.state.password2)) {
          errors['password'] = 'Password does not match';
          errors['password2'] = 'Password does not match';
          return true;
        } else if (this.state.password.length < 7
                   || this.state.password2.length < 7) {
          errors['password'] = 'Password requires at least 7 characters long';
          errors['password2'] = 'Password requires at least 7 characters long';
          return true;
        } else if (this.state.registrationNo.length < 5) {
          errors['registrationNo'] = 'Please enter your Registration No.';
          return true;
        } else {
          errors['password']  = null;
          errors['password2'] = null;
        } 
      }

      if (fieldname === 'statetax') {
        if (this.state.statetax.length < 2) {
          errors['statetax'] = 'Please select a state';
          return true;
        }
      }

      if (!value && ['email', 'password', 'password2','modal_terms','modal_privacy'].indexOf(fieldname) === -1) {
        errors[fieldname] = 'This field is required';
      } else {
        errors[fieldname] = null;
      }
    });

    let isValid = true;
    Object.values(errors).map((errmsg) => {
      isValid &= (errmsg === null);
      return true;
    });

    this.setState({
      errors: {...errors},
    });

    return isValid;
  }

  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const grecaptcha = global.grecaptcha;

    e.preventDefault();
    grecaptcha.ready(() => {
      grecaptcha
        .execute('6LetaBolAAAAAEjF69vzuiz4FFSfMN_oIQPC2p2E', {action: 'submit'})
        .then((token) => {

        const isFormValid = this.validateForm();
        // console.log(isFormValid);
        const errors = {...this.state.errors};
        let message = `${this.state.message}`;

        // console.info('isFormValid: ', isFormValid, ', ', this.state.errors);

        if (isFormValid) {
          const state = statemap[this.state.statetax];
          const stateabbr = stateabbs[state];
          const form = {
            ...this.state,
            state,
            stateabbr,
            currency_code: 'USD',
            currency: '$',
          };
          
          form.statetax = form.statetax.substr(0, 5);

          // console.info('form: ', form);return;
          let post = true;
          if(post){
            axios.post('https://app.fixnetics.com/Company/api_add?allow-all-origin=1', {
              company_name: form.companyname,
              country_id: 2,
              tax_ref: 'B1234',
              type: 'basic_workshop',
              email: form.email,
              password: form.password,
              firstname: form.firstname,
              lastname: form.lastname,
              ...form,
            }).then((result) => {
              console.log(result);
              if (result.status === 200 && result.data && result.data.success) {
                message = 'Thank you for registering. Please check your email and verify your account first.';
                this.setState({
                  message,
                });
                // this.onReset();
              } else if (result.data && result.data.error) {
                errors.serverErrors = result.data.error.join(',');
                this.setState({
                  errors,
                });
              }
            }).catch((error) => {
              console.info('error: ', error);
            });
          }
          
        }

        return false;
        
      });
    });
  }

  onReset = () => {
    this.setState({...this.initState});
  }
  getYear() {
    return new Date().getFullYear();
  }
  toggle_terms() {
    this.setState({
      modal_terms: !this.state.modal_terms
    });
  }
  toggle_privacy() {
    this.setState({
      modal_privacy: !this.state.modal_privacy
    });
  }
  render () {
    const { modal_terms } = this.state;
    const { modal_privacy } = this.state;
    return (
      <Router>
        <Route exact path="/" render={
          (props) => (
            <React.Fragment>
              <video id="background-video" autoPlay loop muted>
                <source src={videoBackground} type="video/mp4" />
              </video>
              <Container fluid className="bg-light-blue " >
                <Navbar  expand="md" >
                  <Container fluid className="mt-2 mb-2">

                      <Col md={1} sm={0}>{}</Col>
                      <Col md={3} sm={6} xs={12} className="visible-xs" style={{textAlign:"center",paddingLeft:"0px"}}>
                        
                          <NavbarBrand href="#" className="pt-0 pb-0 ">
                            <img src={logo} alt="Logo" className="img-logo"/>
                          </NavbarBrand>
                        
                      </Col>
                      <Col md={4} sm={1} xs={1}>{}</Col>
                      <Col md={4} sm={3} xs={12}className="ml-md-5">
                        <div className="visible-xs" style={{textAlign:"center"}}>
                          <Nav navbar className="">
                            <NavItem 
                              active={true}>
                              <NavLink className="color-pink f-20px"
                                href="https://app.fixnetics.com">
                                 <img src={loginIcon} alt="Logo" className="img-login-icon mr-2"/>{}{}
                                <u><b>Log In</b></u>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                        
                      </Col>
                  </Container>
                </Navbar>
              </Container>

              <Container className="mt-3 mt-md-5">
                 <Form onSubmit={this.onSubmit}>
                  <Row>
                    <Col md={1}></Col>
                    <Col md={11} sm={12} xs={12} className="mt-md-5">
                      <span className="title"> Sign up now to get your Fixnetics 6-month free trial</span>
                      <span className="f-13px bold-500">This promotional offer is available for a limited number of slots only.</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={1} sm={0}></Col>
                    <Col md={8} sm={12}>
                      {
                        this.state.errors.serverErrors && (
                          <Alert theme="danger">
                            Service returned: { this.state.errors.serverErrors }
                          </Alert>
                        )
                      }
                      {
                        this.state.message && (
                          <Alert theme="success">
                            { this.state.message }
                          </Alert>
                        )
                      }
                    </Col>
                  </Row>
                  
                  <Row className="mt-4 mt-md-5"> 
                   <Col md={1}></Col>
                    <Col md={4} sm={6} xs={12}>
                        <FormGroup inline>
                          <label htmlFor="#email">Email address</label>
                          <FormInput className="placeholder-italic"
                            type="email"
                            name="email"
                            placeholder="Email address"
                            value={this.state.email}
                            onChange={this.onChange}
                          />
                          {this.state.errors.email && <span className="error">{this.state.errors.email}</span>}
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup inline>
                              <label htmlFor="#firtname">First Name</label>
                              <FormInput className="placeholder-italic"
                                type="text"
                                name="firstname"
                                placeholder="First Name"
                                value={this.state.firstname}
                                onChange={this.onChange}
                              />
                              {this.state.errors.firstname && <span className="error">{this.state.errors.firstname}</span>}
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup inline>
                              <label htmlFor="#lastname">Last Name</label>
                              <FormInput className="placeholder-italic"
                                type="text"
                                name="lastname"
                                placeholder="Last Name"
                                value={this.state.lastname}
                                onChange={this.onChange}
                              />
                              {this.state.errors.lastname && <span className="error">{this.state.errors.lastname}</span>}
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup inline>
                          <label htmlFor="#companyname">
                            Workshop Name
                          </label>
                          <FormInput className="placeholder-italic"
                            type="text"
                            name="companyname"
                            placeholder="Enter business/company name"
                            value={this.state.companyname}
                            onChange={this.onChange}
                          />
                          {this.state.errors.companyname && <span className="error">{this.state.errors.companyname}</span>}
                        </FormGroup>
                    </Col>
                    <Col md={4} sm={6} xs={12}>
                        <FormGroup inline>
                          <label htmlFor="#password">Password</label>
                          <FormInput className="placeholder-italic"
                            type="password"
                            name="password"
                            placeholder="Password (at least 7 characters)"
                            value={this.state.password}
                            onChange={this.onChange}
                          />
                          {this.state.errors.password && <span className="error">{this.state.errors.password}</span>}
                        </FormGroup>
                        <FormGroup inline>
                          <label htmlFor="#password2">Confirm password</label>
                          <FormInput className="placeholder-italic"
                            type="password"
                            name="password2"
                            placeholder="Re-type Password"
                            value={this.state.password2}
                            onChange={this.onChange}
                          />
                          {this.state.errors.password2 && <span className="error">{this.state.errors.password2}</span>}
                        </FormGroup>
                       
                        <FormGroup inline>
                          <label htmlFor="#password2">Sales Tax</label>
                          <StateTax
                            name="statetax"
                            value={this.state.statetax}
                            onChange={this.onChange.bind(this)}
                          />
                          {this.state.errors.statetax && <span className="error">{this.state.errors.statetax}</span>}
                        </FormGroup>
                    </Col>
                    <Col md={3}>{}</Col>
                  </Row>

                  <Row>
                    <Col md={1} sm={0}></Col>
                    <Col md={11} sm={12} className="mt-3 f-13px" >
                      <input name="pdpa" id="pdpa" type="checkbox" 
                        checked={this.state.pdpa} 
                        onChange={this.onChange}/> {}
                      <b>By clicking on the Submit button, you are acknowledging and agreeing to our <a href="https://www.fixnetics.com/terms-of-service" target="_blank" >Terms of Use </a> and our <a href="https://www.fixnetics.com/privacy-policy" target="_blank" >Privacy Policy</a></b>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={3}  className="hidden-xs"></Col>
                    <Col md={2} sm={6} xs={12} style={{textAlign:"right"}}>
                      <div className="visible-xs mb-2" style={{textAlign:"center"}}>
                        <Button disabled={!this.state.pdpa} className="action-btn bg-pink " theme="dark" type="submit"><b>Submit</b></Button>
                      </div>
                    </Col>
                    <Col md={2} sm={6} xs={12}>
                      <div className="visible-xs mb-4" style={{textAlign:"center"}}>
                        <Button
                          theme="light"
                          className="action-btn"
                          onClick={this.onReset}>Clear</Button>
                        </div>
                    </Col>
                    <Col></Col>
                  </Row>

                  <Row className="mt-5 mb-3" style={{fontSize:"11px"}}>
                    <Col md={1} xs={0} className="hidden-xs"></Col>
                    <Col md={9} sm={12}>©{this.getYear()} by Fixnetics. Powered by DewTouch Innovations Pte Ltd.</Col>
                    <Col></Col>
                  </Row>
                  <Row className="mt-1"></Row>

                  <Modal size="lg" open={modal_terms} toggle={this.toggle_terms} className="modal-fullscreen">
                    <ModalHeader className="right-button">
                       <Button outline pill theme="light" onClick={this.toggle_terms} className="close" aria-label="Close"> <span aria-hidden="true">&times;</span> </Button>
                    </ModalHeader>
                    <ModalBody>
                      <iframe src="https://www.fixnetics.com/terms-of-service" frameBorder="0" width="100%" height="810"></iframe>
                    </ModalBody>
                  </Modal>

                  <Modal size="lg" open={modal_privacy} toggle={this.toggle_privacy} className="modal-fullscreen">
                    <ModalHeader className="right-button">
                       <Button outline pill theme="light" onClick={this.toggle_privacy} className="close" aria-label="Close"> <span aria-hidden="true">&times;</span> </Button>
                    </ModalHeader>
                    <ModalBody>
                      <iframe src="https://www.fixnetics.com/privacy-policy" frameBorder="0" width="100%" height="810"></iframe>
                    </ModalBody>
                  </Modal>
                </Form>
              </Container>
              
            </React.Fragment>
          )
        }/>
      </Router>
    );
  }
}

export default Main;