function StateTax(props) {
  
  return (
    <select
      className="form-control tax-dropdown"
      title="Select a State"
      name={props.name}
      value={props.value}
      onChange={props.onChange}>
      <option value="">Select State</option>
      <option value="4.000-1">Alabama - 4.0%</option>
      <option value="0.000-2">Alaska - 0.0%</option>
      <option value="5.600-3">Arizona - 5.6%</option>
      <option value="6.500-4">Arkansas - 6.5%</option>
      <option value="7.500-5">California - 7.5%</option>
      <option value="2.900-6">Colorado - 2.9%</option>
      <option value="6.350-7">Connecticut - 6.3%</option>
      <option value="0.000-8">Delaware - 0.0%</option>
      <option value="5.750-9">District of Columbia - 5.8%</option>
      <option value="6.000-10">Florida - 6.0%</option>
      <option value="4.000-11">Georgia - 4.0%</option>
      <option value="4.000-12">Hawaii - 4.0%</option>
      <option value="6.000-13">Idaho - 6.0%</option>
      <option value="6.250-14">Illinois - 6.3%</option>
      <option value="7.000-15">Indiana - 7.0%</option>
      <option value="6.000-16">Iowa - 6.0%</option>
      <option value="6.150-17">Kansas - 6.2%</option>
      <option value="6.000-18">Kentucky - 6.0%</option>
      <option value="4.000-19">Louisiana - 4.0%</option>
      <option value="5.500-20">Maine - 5.5%</option>
      <option value="6.000-21">Maryland - 6.0%</option>
      <option value="6.250-22">Massachusetts - 6.3%</option>
      <option value="6.000-23">Michigan - 6.0%</option>
      <option value="6.875-24">Minnesota - 6.9%</option>
      <option value="7.000-25">Mississippi - 7.0%</option>
      <option value="4.225-26">Missouri - 4.2%</option>
      <option value="0.000-27">Montana - 0.0%</option>
      <option value="5.500-28">Nebraska - 5.5%</option>
      <option value="6.850-29">Nevada - 6.8%</option>
      <option value="0.000-30">New Hampshire - 0.0%</option>
      <option value="7.000-31">New Jersey - 7.0%</option>
      <option value="5.125-32">New Mexico - 5.1%</option>
      <option value="4.000-33">New York - 4.0%</option>
      <option value="4.750-34">North Carolina - 4.8%</option>
      <option value="5.000-35">North Dakota - 5.0%</option>
      <option value="5.750-36">Ohio - 5.8%</option>
      <option value="4.500-37">Oklahoma - 4.5%</option>
      <option value="0.000-38">Oregon - 0.0%</option>
      <option value="6.000-39">Pennsylvania - 6.0%</option>
      <option value="7.000-40">Rhode Island - 7.0%</option>
      <option value="6.000-41">South Carolina - 6.0%</option>
      <option value="4.000-42">South Dakota - 4.0%</option>
      <option value="7.000-43">Tennessee - 7.0%</option>
      <option value="6.250-44">Texas - 6.3%</option>
      <option value="4.700-45">Utah - 4.7%</option>
      <option value="6.000-46">Vermont - 6.0%</option>
      <option value="4.300-47">Virginia - 4.3%</option>
      <option value="6.500-48">Washington - 6.5%</option>
      <option value="6.000-49">West Virginia - 6.0%</option>
      <option value="5.000-50">Wisconsin - 5.0%</option>
      <option value="4.000-51">Wyoming - 4.0%</option>
    </select>
  );
};

const map = {
  "4.000-1":"Alabama",
  "0.000-2":"Alaska",
  "5.600-3":"Arizona",
  "6.500-4":"Arkansas",
  "7.500-5":"California",
  "2.900-6":"Colorado",
  "6.350-7":"Connecticut",
  "0.000-8":"Delaware",
  "5.750-9":"District of Columbia",
  "6.000-10":"Florida",
  "4.000-11":"Georgia",
  "4.000-12":"Hawaii",
  "6.000-13":"Idaho",
  "6.250-14":"Illinois",
  "7.000-15":"Indiana",
  "6.000-16":"Iowa",
  "6.150-17":"Kansas",
  "6.000-18":"Kentucky",
  "4.000-19":"Louisiana",
  "5.500-20":"Maine",
  "6.000-21":"Maryland",
  "6.250-22":"Massachusetts",
  "6.000-23":"Michigan",
  "6.875-24":"Minnesota",
  "7.000-25":"Mississippi",
  "4.225-26":"Missouri",
  "0.000-27":"Montana",
  "5.500-28":"Nebraska",
  "6.850-29":"Nevada",
  "0.000-30":"New Hampshire",
  "7.000-31":"New Jersey",
  "5.125-32":"New Mexico",
  "4.000-33":"New York",
  "4.750-34":"North Carolina",
  "5.000-35":"North Dakota",
  "5.750-36":"Ohio",
  "4.500-37":"Oklahoma",
  "0.000-38":"Oregon",
  "6.000-39":"Pennsylvania",
  "7.000-40":"Rhode Island",
  "6.000-41":"South Carolina",
  "4.000-42":"South Dakota",
  "7.000-43":"Tennessee",
  "6.250-44":"Texas",
  "4.700-45":"Utah",
  "6.000-46":"Vermont",
  "4.300-47":"Virginia",
  "6.500-48":"Washington",
  "6.000-49":"West Virginia",
  "5.000-50":"Wisconsin",
  "4.000-51":"Wyoming",
};

const abbs = {
'Arizona': 'AZ',
'Alabama': 'AL',
'Alaska':'AK',
'Arkansas': 'AR',
'California': 'CA',
'Colorado': 'CO',
'Connecticut': 'CT',
'District of Columbia': 'DC',
'Delaware': 'DE',
'Florida': 'FL',
'Georgia': 'GA',
'Hawaii': 'HI',
'Idaho': 'ID',
'Illinois': 'IL',
'Indiana': 'IN',
'Iowa': 'IA',
'Kansas': 'KS',
'Kentucky': 'KY',
'Louisiana': 'LA',
'Maine': 'ME',
'Maryland': 'MD',
'Massachusetts': 'MA',
'Michigan': 'MI',
'Minnesota': 'MN',
'Mississippi': 'MS',
'Missouri': 'MO',
'Montana': 'MT',
'Nebraska': 'NE',
'Nevada': 'NV',
'New Hampshire': 'NH',
'New Jersey': 'NJ',
'New Mexico': 'NM',
'New York': 'NY',
'North Carolina': 'NC',
'North Dakota': 'ND',
'Ohio': 'OH',
'Oklahoma': 'OK',
'Oregon': 'OR',
'Pennsylvania': 'PA',
'Rhode Island': 'RI',
'South Carolina': 'SC',
'South Dakota': 'SD',
'Tennessee': 'TN',
'Texas': 'TX',
'Utah': 'UT',
'Vermont': 'VT',
'Virginia': 'VA',
'Washington': 'WA',
'West Virginia': 'WV',
'Wisconsin': 'WI',
'Wyoming': 'WY',
};

export {
  StateTax,
  map as statemap,
  abbs as stateabbs,
};